import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2><a id="characer-classes"></a>Character Classes</h2>
    <h3><a id="sorcerer"></a>SORCERER</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td className="line"> </td>
        </tr>
      </tbody>
    </table>
    <p className="initial"><a href="http://www.wizards.com/dnd/images/ph35_gallery/PHB35_PG51_WEB.jpg"><img className="art-wide" src="pict.jpg" title="" alt="pict" style={{
          "border": "0px solid",
          "width": "73px",
          "height": "75px"
        }} /></a><b>Alignment</b>:
Any.</p>
    <p><b>Hit Die</b>: d4.</p>
    <h6>Class Skills</h6>
    <p className="initial">The sorcerer&#8217;s class skills (and the key
ability for each skill) are <a href="skillsAll.html#bluff" style={{
        "color": "rgb(87, 158, 182)"
      }}>Bluff</a>
(Cha), <a href="skillsAll.html#concentration" style={{
        "color": "rgb(87, 158, 182)"
      }}>Concentration</a> (Con), <a href="skillsAll.html#craft" style={{
        "color": "rgb(87, 158, 182)"
      }}>Craft</a>
(Int), <a href="skillsAll.html#knowledge" style={{
        "color": "rgb(87, 158, 182)"
      }}>Knowledge</a> (arcana) (Int), <a href="skillsAll.html#profession" style={{
        "color": "rgb(87, 158, 182)"
      }}>Profession</a>
(Wis), and <a href="skillsAll.html#spellcraft" style={{
        "color": "rgb(87, 158, 182)"
      }}>Spellcraft</a> (Int).</p>
    <p><b>Skill Points at 1st Level</b>: (2 + Int modifier) x 4.</p>
    <p><b>Skill Points at Each Additional Level</b>: 2 + Int modifier.</p>
    <p><a id="table-the-sorcerer"></a><b>Table: The Sorcerer</b></p>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr valign="bottom">
          <th rowSpan="2" style={{
            "width": "6%"
          }}>Level</th>
          <th rowSpan="2" style={{
            "width": "12%"
          }}>Base
Attack Bonus</th>
          <th rowSpan="2" style={{
            "width": "6%"
          }}>Fort
Save</th>
          <th rowSpan="2" style={{
            "width": "6%"
          }}>Ref
Save</th>
          <th rowSpan="2" style={{
            "width": "6%"
          }}>Will
Save</th>
          <th rowSpan="2" style={{
            "width": "34%"
          }}>Special</th>
          <th colSpan="10" style={{
            "textAlign": "center"
          }}>Spells per Day</th>
        </tr>
        <tr>
          <th style={{
            "width": "3%"
          }}>0</th>
          <th style={{
            "width": "3%"
          }}>1st</th>
          <th style={{
            "width": "3%"
          }}>2nd</th>
          <th style={{
            "width": "3%"
          }}>3rd</th>
          <th style={{
            "width": "3%"
          }}>4th</th>
          <th style={{
            "width": "3%"
          }}>5th</th>
          <th style={{
            "width": "3%"
          }}>6th</th>
          <th style={{
            "width": "3%"
          }}>7th</th>
          <th style={{
            "width": "3%"
          }}>8th</th>
          <th style={{
            "width": "3%"
          }}>9th</th>
        </tr>
        <tr className="odd-row">
          <td>1st</td>
          <td>+0</td>
          <td>+0</td>
          <td>+0</td>
          <td>+2</td>
          <td>Summon familiar</td>
          <td>5</td>
          <td>3</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr>
          <td>2nd</td>
          <td>+1</td>
          <td>+0</td>
          <td>+0</td>
          <td>+3</td>
          <td>&nbsp;</td>
          <td>6</td>
          <td>4</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr className="odd-row">
          <td>3rd</td>
          <td>+1</td>
          <td>+1</td>
          <td>+1</td>
          <td>+3</td>
          <td>&nbsp;</td>
          <td>6</td>
          <td>5</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr>
          <td>4th</td>
          <td>+2</td>
          <td>+1</td>
          <td>+1</td>
          <td>+4</td>
          <td>&nbsp;</td>
          <td>6</td>
          <td>6</td>
          <td>3</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr className="odd-row">
          <td>5th</td>
          <td>+2</td>
          <td>+1</td>
          <td>+1</td>
          <td>+4</td>
          <td>&nbsp;</td>
          <td>6</td>
          <td>6</td>
          <td>4</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr>
          <td>6th</td>
          <td>+3</td>
          <td>+2</td>
          <td>+2</td>
          <td>+5</td>
          <td>&nbsp;</td>
          <td>6</td>
          <td>6</td>
          <td>5</td>
          <td>3</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr className="odd-row">
          <td>7th</td>
          <td>+3</td>
          <td>+2</td>
          <td>+2</td>
          <td>+5</td>
          <td>&nbsp;</td>
          <td>6</td>
          <td>6</td>
          <td>6</td>
          <td>4</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr>
          <td>8th</td>
          <td>+4</td>
          <td>+2</td>
          <td>+2</td>
          <td>+6</td>
          <td>&nbsp;</td>
          <td>6</td>
          <td>6</td>
          <td>6</td>
          <td>5</td>
          <td>3</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr className="odd-row">
          <td>9th</td>
          <td>+4</td>
          <td>+3</td>
          <td>+3</td>
          <td>+6</td>
          <td>&nbsp;</td>
          <td>6</td>
          <td>6</td>
          <td>6</td>
          <td>6</td>
          <td>4</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr>
          <td>10th</td>
          <td>+5</td>
          <td>+3</td>
          <td>+3</td>
          <td>+7</td>
          <td>&nbsp;</td>
          <td>6</td>
          <td>6</td>
          <td>6</td>
          <td>6</td>
          <td>5</td>
          <td>3</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr className="odd-row">
          <td>11th</td>
          <td>+5</td>
          <td>+3</td>
          <td>+3</td>
          <td>+7</td>
          <td>&nbsp;</td>
          <td>6</td>
          <td>6</td>
          <td>6</td>
          <td>6</td>
          <td>6</td>
          <td>4</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr>
          <td>12th</td>
          <td>+6/+1</td>
          <td>+4</td>
          <td>+4</td>
          <td>+8</td>
          <td>&nbsp;</td>
          <td>6</td>
          <td>6</td>
          <td>6</td>
          <td>6</td>
          <td>6</td>
          <td>5</td>
          <td>3</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr className="odd-row">
          <td>13th</td>
          <td>+6/+1</td>
          <td>+4</td>
          <td>+4</td>
          <td>+8</td>
          <td>&nbsp;</td>
          <td>6</td>
          <td>6</td>
          <td>6</td>
          <td>6</td>
          <td>6</td>
          <td>6</td>
          <td>4</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr>
          <td>14th</td>
          <td>+7/+2</td>
          <td>+4</td>
          <td>+4</td>
          <td>+9</td>
          <td>&nbsp;</td>
          <td>6</td>
          <td>6</td>
          <td>6</td>
          <td>6</td>
          <td>6</td>
          <td>6</td>
          <td>5</td>
          <td>3</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr className="odd-row">
          <td>15th</td>
          <td>+7/+2</td>
          <td>+5</td>
          <td>+5</td>
          <td>+9</td>
          <td>&nbsp;</td>
          <td>6</td>
          <td>6</td>
          <td>6</td>
          <td>6</td>
          <td>6</td>
          <td>6</td>
          <td>6</td>
          <td>4</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr>
          <td>16th</td>
          <td>+8/+3</td>
          <td>+5</td>
          <td>+5</td>
          <td>+10</td>
          <td>&nbsp;</td>
          <td>6</td>
          <td>6</td>
          <td>6</td>
          <td>6</td>
          <td>6</td>
          <td>6</td>
          <td>6</td>
          <td>5</td>
          <td>3</td>
          <td>&#8212;</td>
        </tr>
        <tr className="odd-row">
          <td>17th</td>
          <td>+8/+3</td>
          <td>+5</td>
          <td>+5</td>
          <td>+10</td>
          <td>&nbsp;</td>
          <td>6</td>
          <td>6</td>
          <td>6</td>
          <td>6</td>
          <td>6</td>
          <td>6</td>
          <td>6</td>
          <td>6</td>
          <td>4</td>
          <td>&#8212;</td>
        </tr>
        <tr>
          <td>18th</td>
          <td>+9/+4</td>
          <td>+6</td>
          <td>+6</td>
          <td>+11</td>
          <td>&nbsp;</td>
          <td>6</td>
          <td>6</td>
          <td>6</td>
          <td>6</td>
          <td>6</td>
          <td>6</td>
          <td>6</td>
          <td>6</td>
          <td>5</td>
          <td>3</td>
        </tr>
        <tr className="odd-row">
          <td>19th</td>
          <td>+9/+4</td>
          <td>+6</td>
          <td>+6</td>
          <td>+11</td>
          <td>&nbsp;</td>
          <td>6</td>
          <td>6</td>
          <td>6</td>
          <td>6</td>
          <td>6</td>
          <td>6</td>
          <td>6</td>
          <td>6</td>
          <td>6</td>
          <td>4</td>
        </tr>
        <tr>
          <td className="last-row">20th</td>
          <td className="last-row">+10/+5</td>
          <td className="last-row">+6</td>
          <td className="last-row">+6</td>
          <td className="last-row">+12</td>
          <td className="last-row">&nbsp;</td>
          <td className="last-row">6</td>
          <td className="last-row">6</td>
          <td className="last-row">6</td>
          <td className="last-row">6</td>
          <td className="last-row">6</td>
          <td className="last-row">6</td>
          <td className="last-row">6</td>
          <td className="last-row">6</td>
          <td className="last-row">6</td>
          <td className="last-row">6</td>
        </tr>
      </tbody>
    </table>
    <p><a id="table-sorcerer-spells-known"></a><b>Table: Sorcerer Spells Known</b></p>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "50%"
    }}>
      <tbody>
        <tr>
          <th>&nbsp;</th>
          <th colSpan="10" className="center">Spells Known</th>
        </tr>
        <tr>
          <td>Level</td>
          <td style={{
            "width": "9%"
          }}>0</td>
          <td style={{
            "width": "9%"
          }}>1st</td>
          <td style={{
            "width": "9%"
          }}>2nd</td>
          <td style={{
            "width": "9%"
          }}>3rd</td>
          <td style={{
            "width": "9%"
          }}>4th</td>
          <td style={{
            "width": "9%"
          }}>5th</td>
          <td style={{
            "width": "9%"
          }}>6th</td>
          <td style={{
            "width": "9%"
          }}>7th</td>
          <td style={{
            "width": "9%"
          }}>8th</td>
          <td style={{
            "width": "9%"
          }}>9th</td>
        </tr>
        <tr className="odd-row">
          <td>1st</td>
          <td>4</td>
          <td>2</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr>
          <td>2nd</td>
          <td>5</td>
          <td>2</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr className="odd-row">
          <td>3rd</td>
          <td>5</td>
          <td>3</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr>
          <td>4th</td>
          <td>6</td>
          <td>3</td>
          <td>1</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr className="odd-row">
          <td>5th</td>
          <td>6</td>
          <td>4</td>
          <td>2</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr>
          <td>6th</td>
          <td>7</td>
          <td>4</td>
          <td>2</td>
          <td>1</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr className="odd-row">
          <td>7th</td>
          <td>7</td>
          <td>5</td>
          <td>3</td>
          <td>2</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr>
          <td>8th</td>
          <td>8</td>
          <td>5</td>
          <td>3</td>
          <td>2</td>
          <td>1</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr className="odd-row">
          <td>9th</td>
          <td>8</td>
          <td>5</td>
          <td>4</td>
          <td>3</td>
          <td>2</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr>
          <td>10th</td>
          <td>9</td>
          <td>5</td>
          <td>4</td>
          <td>3</td>
          <td>2</td>
          <td>1</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr className="odd-row">
          <td>11th</td>
          <td>9</td>
          <td>5</td>
          <td>5</td>
          <td>4</td>
          <td>3</td>
          <td>2</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr>
          <td>12th</td>
          <td>9</td>
          <td>5</td>
          <td>5</td>
          <td>4</td>
          <td>3</td>
          <td>2</td>
          <td>1</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr className="odd-row">
          <td>13th</td>
          <td>9</td>
          <td>5</td>
          <td>5</td>
          <td>4</td>
          <td>4</td>
          <td>3</td>
          <td>2</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr>
          <td>14th</td>
          <td>9</td>
          <td>5</td>
          <td>5</td>
          <td>4</td>
          <td>4</td>
          <td>3</td>
          <td>2</td>
          <td>1</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr className="odd-row">
          <td>15th</td>
          <td>9</td>
          <td>5</td>
          <td>5</td>
          <td>4</td>
          <td>4</td>
          <td>4</td>
          <td>3</td>
          <td>2</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr>
          <td>16th</td>
          <td>9</td>
          <td>5</td>
          <td>5</td>
          <td>4</td>
          <td>4</td>
          <td>4</td>
          <td>3</td>
          <td>2</td>
          <td>1</td>
          <td>&#8212;</td>
        </tr>
        <tr className="odd-row">
          <td>17th</td>
          <td>9</td>
          <td>5</td>
          <td>5</td>
          <td>4</td>
          <td>4</td>
          <td>4</td>
          <td>3</td>
          <td>3</td>
          <td>2</td>
          <td>&#8212;</td>
        </tr>
        <tr>
          <td>18th</td>
          <td>9</td>
          <td>5</td>
          <td>5</td>
          <td>4</td>
          <td>4</td>
          <td>4</td>
          <td>3</td>
          <td>3</td>
          <td>2</td>
          <td>1</td>
        </tr>
        <tr className="odd-row">
          <td>19th</td>
          <td>9</td>
          <td>5</td>
          <td>5</td>
          <td>4</td>
          <td>4</td>
          <td>4</td>
          <td>3</td>
          <td>3</td>
          <td>3</td>
          <td>2</td>
        </tr>
        <tr>
          <td className="last-row">20th</td>
          <td className="last-row">9</td>
          <td className="last-row">5</td>
          <td className="last-row">5</td>
          <td className="last-row">4</td>
          <td className="last-row">4</td>
          <td className="last-row">4</td>
          <td className="last-row">3</td>
          <td className="last-row">3</td>
          <td className="last-row">3</td>
          <td className="last-row">3</td>
        </tr>
      </tbody>
    </table>
    <h6><a id="sorcerer-class-features"></a>Class Features</h6>
    <p className="initial">All of the following are class features of the
sorcerer.</p>
    <p><b>Weapon and Armor Proficiency</b>: Sorcerers are proficient
with all simple weapons. They are not proficient with any type of armor
or shield. Armor of any type interferes with a sorcerer&#8217;s gestures,
which can cause his spells with somatic components to fail.</p>
    <a id="sorcerer-spells"></a><p><b>Spells</b>: A sorcerer casts arcane spells which are drawn
primarily from the <a style={{
        "color": "#579eb6"
      }} href="wizardSpells.html">sorcerer/wizard spell list</a>. He can cast any spell he
knows without preparing it ahead of time, the way a wizard or a cleric
must (see below).</p>
    <p>To learn or cast a spell, a sorcerer must have a Charisma
score equal to at least 10 + the spell level. The Difficulty Class for
a saving throw against a sorcerer&#8217;s spell is 10 + the spell level + the
sorcerer&#8217;s Charisma modifier.</p>
    <p>Like other spellcasters, a sorcerer can cast only a certain
number of spells of each spell level per day. His base daily spell
allotment is given on Table: The Sorcerer. In addition, he receives
bonus spells per day if he has a high Charisma score.</p>
    <p>A sorcerer&#8217;s selection of spells is extremely limited. A
sorcerer begins play knowing four 0-level spells and two 1st-level
spells of your choice. At each new sorcerer level, he gains one or more
new spells, as indicated on Table: Sorcerer Spells Known. (Unlike
spells per day, the number of spells a sorcerer knows is not affected
by his Charisma score; the numbers on Table: Sorcerer Spells Known are
fixed.) These new spells can be common spells chosen from the
sorcerer/wizard spell list, or they can be unusual spells that the
sorcerer has gained some understanding of by study. The sorcerer can&#8217;t
use this method of spell acquisition to learn spells at a faster rate,
however.</p>
    <p>Upon reaching 4th level, and at every even-numbered sorcerer
level after that (6th, 8th, and so on), a sorcerer can choose to learn
a new spell in place of one he already knows. In effect, the sorcerer
&#8220;loses&#8221; the old spell in exchange for the new one. The new spell&#8217;s
level must be the same as that of the spell being exchanged, and it
must be at least two levels lower than the highest-level sorcerer spell
the sorcerer can cast. A sorcerer may swap only a single spell at any
given level, and must choose whether or not to swap the spell at the
same time that he gains new spells known for the level.</p>
    <p>Unlike a wizard or a cleric, a sorcerer need not prepare his
spells in advance. He can cast any spell he knows at any time, assuming
he has not yet used up his spells per day for that spell level. He does
not have to decide ahead of time which spells he&#8217;ll cast.</p>
    <p><a id="sorcerer-familiar"></a><b>Familiar</b>: A sorcerer can obtain a <a href="wizard.html#familiars" style={{
        "color": "rgb(87, 158, 182)"
      }}>familiar</a>.
Doing so takes 24 hours and
uses up magical materials that cost 100 gp. A familiar is a magical
beast that resembles a small animal and is unusually tough and
intelligent. The creature serves as a companion and servant.</p>
    <p>The sorcerer chooses the kind of familiar he gets. As the
sorcerer advances in level, his familiar also increases in power.</p>
    <p>If the familiar dies or is dismissed by the sorcerer, the
sorcerer must attempt a DC 15 Fortitude saving throw. Failure means he
loses 200 experience points per sorcerer level; success reduces the
loss to one-half that amount. However, a sorcerer&#8217;s experience point
total can never go below 0 as the result of a familiar&#8217;s demise or
dismissal. A slain or dismissed familiar cannot be replaced for a year
and day. A slain familiar can be raised from the dead just as a
character can be, and it does not lose a level or a Constitution point
when this happy event occurs.</p>
    <p>A character with more than one class that grants a familiar
may have only one familiar at a time.</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      